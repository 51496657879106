import { useModalState } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Box, ThemeProvider } from "@mui/material";
import {
  SENT_HOME_REQUEST_ERROR_CODES,
  useCreateSentHomeRequest,
} from "@src/appV2/Agents/api/useCreateSentHomeRequest";
import { useWorkerSentHomeCancellationPayParams } from "@src/appV2/Agents/api/useWorkerSentHomeCancellationPayParams";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { Button } from "@src/appV2/redesign/components/Button";
import { PageWrapper } from "@src/appV2/redesign/components/PageWrapper";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { StickyFooter } from "../../components/StickyFooter";
import { CreateSentHomeConfirmationBottomSheet } from "./CreateSentHomeConfirmationBottomSheet";
import { CreateSentHomeRequestForm } from "./CreateSentHomeRequestForm";
import { CreateSentHomeRequestInfo } from "./CreateSentHomeRequestInfo";
import { CreateSentHomeRequestSkeleton } from "./CreateSentHomeRequestSkeleton";
// eslint-disable-next-line import/max-dependencies
import { type CreateSentHomeRequestFormValues, createSentHomeRequestSchema } from "./types";

interface RouteParams {
  shiftId: string;
}

export function CreateSentHomeRequestPage() {
  const { shiftId } = useParams<RouteParams>();
  const { showErrorToast } = useToast();
  const worker = useDefinedWorker();
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(null);
  const confirmationModalState = useModalState();

  const { data: shiftData } = useGetShift(shiftId, {
    enabled: isDefined(shiftId) && shiftId.length > 0,
  });

  const shift = shiftData?.response;

  const {
    data: sentHomePayoutParameterResponse,
    isLoading,
    isSuccess,
    isError,
  } = useWorkerSentHomeCancellationPayParams(shiftId, {
    enabled: isDefined(shiftId) && shiftId.length > 0,
  });

  const { mutateAsync: submitSentHomeRequest } = useCreateSentHomeRequest();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<CreateSentHomeRequestFormValues>({
    resolver: zodResolver(createSentHomeRequestSchema),
    defaultValues: {
      description: "",
    },
  });

  const shouldShowSentHomeRequestLineItems =
    isSuccess && isDefined(sentHomePayoutParameterResponse);

  const isSentHomeRequestPayable =
    isSuccess &&
    sentHomePayoutParameterResponse.isPayable &&
    sentHomePayoutParameterResponse.time > 0 &&
    sentHomePayoutParameterResponse.payableAmount > 0;

  const onSubmit = async (data: CreateSentHomeRequestFormValues) => {
    try {
      await submitSentHomeRequest({
        shiftId,
        data: {
          reason: "SENT_HOME_BY_FACILITY",
          description: data.description,
          atFacility: true,
        },
      });

      confirmationModalState.openModal();

      logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_SENT_HOME_TAPPED, {
        shiftId,
        workerId: worker.userId,
        workplaceId: shift?.facility.userId,
        description: "",
      });
    } catch (error) {
      const errorResponse = error as { status?: number };
      if (errorResponse.status === SENT_HOME_REQUEST_ERROR_CODES.ALREADY_CLOCKED_OUT) {
        showErrorToast("You cannot submit a sent home request after clocking out.");
        return;
      }

      logError("Error submitting sent home request", {
        error,
        metadata: {
          shiftId,
          facilityId: shift?.facility.userId,
        },
      });
      showErrorToast("Something went wrong while submitting your request. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <StickyScrollPageHeader
          title="Sent home request"
          isLoading={isLoading}
          scrollContainerRef={scrollRef}
          headerVariant="tertiary"
        />

        <Box
          ref={scrollRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLoading && <CreateSentHomeRequestSkeleton />}
            {isError && <Alert severity="error">Error loading sent home request details.</Alert>}
            {isSuccess && isDefined(shift) && (
              <>
                <CreateSentHomeRequestInfo
                  facilityName={shift.facility.name ?? ""}
                  shouldShowSentHomeRequestLineItems={shouldShowSentHomeRequestLineItems}
                  isSentHomeRequestPayable={isSentHomeRequestPayable}
                  payDurationInHours={sentHomePayoutParameterResponse?.time ?? 0}
                  payableAmountInDollars={sentHomePayoutParameterResponse?.payableAmount ?? 0}
                />
                <CreateSentHomeRequestForm control={control} />
              </>
            )}
          </form>
        </Box>

        <StickyFooter>
          <Button
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            disabled={isSubmitting || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Request workplace verification
          </Button>
        </StickyFooter>

        <CreateSentHomeConfirmationBottomSheet
          modalState={confirmationModalState}
          onContinue={() => {
            history.push(DeprecatedGlobalAppV1Paths.MY_SHIFTS);
          }}
        />
      </PageWrapper>
    </ThemeProvider>
  );
}
